//dashboard
export const GET_DYNAMIC_INFO = 'getuserdynamicinfo';

// Client Actions

// export const GETALLCLIENTS = 'getallclients';
export const GETCLIENTS = 'getclients'; // /clientid
export const GETACTIVECLIENTS = 'getactiveclients';
export const GETACTIVENONCHILDCLIENTSBYTYPE = 'getactivenonchildclientsbytype';

export const GETCLIENTRELATIONSHIPS = 'getclientrelationships';
export const GETCLIENTSUMMARYINFO = 'getclientsummaryinfo';
export const GETPROSPECTIVECLIENTSUMMARYINFO = 'getprospectiveclientsummaryinfo';
export const GETINVESTMENTTRADES = 'getinvestmenttrades';
export const GETTRANSACTIONS4WORKFLOW = 'gettransactions4workflow';
export const GETTITLES = 'gettitles';
export const GETCLIENTPARTNERS = 'getclientpartners';
export const GETCLIENTCLASS = 'getclientclass';
export const GETCOUNTRIES = 'getcountries';
export const GETUSERACCESSRIGHTS = 'getuseraccessrights';
export const GETCLIENTDOCUMENTTEMPLATES = 'getclientdocumenttemplates';
export const GETCLIENTUPLOADEDDOCUMENTS = 'getclientuploadeddocuments';
export const GETCLIENTSIGNATORIES = 'getclientsignatories';
export const GETCLIENTNEXTOFKINS = 'getclientnextofkins';
export const GETCLIENTBANKACCOUNTS = 'getclientbankaccounts';
export const GETACTIVECLIENTBANKACCOUNTS = 'getactiveclientbankaccounts';
export const GETCLIENTBANKACCOUNT = 'getclientbankaccount';
export const CREATEORUPDATEBANKACCOUNTS = 'createorupdateclientbankaccounts';
export const UPLOADCLIENTDOCUMENTS = 'uploadclientdocuments';
export const CREATEORUPDATECLIENTSIGNATORY = 'createorupdateclientsignatory';
export const GETCLIENTSIGNATORY = 'getclientsignatory';
export const CREATEORUPDATENEXTOFKIN = 'createorupdateclientnextofkin';
export const GETCLIENTNEXTKIN = 'getclientnextofkin';
export const DOWNLOADDOCUMENT = 'downloaddocument';
export const GETIDENTITYTYPES = 'getidentitytypes';
export const CREATEORUPDATETITLES = 'createorupdatetitles';
export const CREATEORUPDATEPRODUCTS = 'createorupdateliabilityproducts';
export const CREATEORUPDATELIABILITYPLACEMENTS = 'createorupdateliabilityplacement';
export const CREATEPOSTNODEBIT = 'createpostnodebit';
export const RELEASEPOSTNODEBITS = 'releasepostnodebits';
export const APPROVEEPOSTNODEBITS = 'approvepostnodebits';
export const REJECTPOSTNODEBITS = 'rejectpostnodebits';
export const SUBMITPOSTNODEBITS = 'submitpostnodebits';
export const INVALIDATEPOSTNODEBITS = 'invalidatepostnodebits';
export const GETUNSUBMITTEDPOSTNODEBITS = 'getunsubmittedpostnodebits';
export const GETSUBMITTEDPOSTNODEBITS = 'getsubmittedpostnodebits';

export const CREATEORUPDATECLIENTPARTNERS = 'createorupdateclientpartners';
export const CREATEORUPDATECLIENTCLASS = 'createorupdateclientclass';
export const CREATEORUPDATECOUNTRIES = 'createorupdatecountries';
export const CREATEORUPDATEIDENTITYTYPES = 'createorupdateidentitytypes';
export const GETKYCPROFILES = 'getkycprofiles';
export const CREATEORUPDATEKYCPROFILES = 'createorupdatekycprofiles';
export const CREATEORUPDATECLIENTRELATIONSHIPS = 'createorupdateclientrelationships';

export const CREATEORUPDATECLIENTGROUP = 'createorupdateclientgroup';
export const GETKYCPROFILETEMPLATES = 'getkycprofiletemplates'; // /profileid
export const CREATEORUPDATEKYCPROFILETEMPLATES = 'createorupdatekycprofiletemplates';
export const GETCLIENTGROUPS = 'getclientgroups';
export const GETCASHCLASSLIABILITYAWAITINGAPPROVAL = 'getcashliabilityawaitingapproval';
export const CREATEORUPDATECASHLIABILITYSUBSCRIPTION = 'createorupdatecashliabilitysubscription';
export const CREATEORUPDATECASHLIABILITYREDEMPTION = 'createorupdatecashliabilityredemption';
export const APPROVEMONEYMARKETTRADE = 'approvemoneymarkettrade';
export const APPROVEBONDPURCAHSETRADE = 'approvebondpurchasetrade';
export const APPROVEFIXEDINCOMESALETRADE = 'approvefixedincomesaletrade';
export const APPROVESTOCKTRADE = 'approvestocktrade';
export const GETMUTUALFUNDPORTFOLIO = 'getmutualfundportfolios';
export const GETMUTUALFUNDSCHEMES = 'getmutualfundschemes';
export const SETCLIENTDIVIDENDPAYOUTMODEREQUEST = 'setclientdividendpayoutmoderequest';
export const GETPENDINGCLIENTDIVIDENDPAYOUTMODEREQUEST = 'getpendingclientdividendpayoutmoderequest';
export const APPROVECLIENTDIVIDENDPAYOUTMODEREQUEST = 'approveclientdividendpayoutmoderequest';
export const GETFIXEDINCOMELIABILITY4SELECTION = 'getfixedincomeliability4selection';
export const GETFIXEDINCOMETEMP = 'getfixedincometemp';
export const GETSUBMITTEDMUTUALFUNDTRANSFER = 'getsubmittedmutualfundtransfer';
export const CREATEORUPDATEMUTUALFUNDTRANSFER = 'createorupdatemutualfundtransfer';
export const GETUNSUBMITTEDMUTUALFUNDTRANSFER = 'getunsubmittedmutualfundtransfer';
export const GETMUTUALFUNDTRANSFER = 'getmutualfundtransfer';
export const SUBMITMUTUALFUNDTRANSFER = 'submitmutualfundtransfer';
export const INVALIDATEMUTUALFUNDTRANSFER = 'invalidatemutualfundtransfer';
export const APPROVEMUTUALFUNDTRANSFER = 'approvemutualfundtransfer';
export const REJECTMUTUALFUNDTRANSFER = 'rejectmutualfundtransfer';
export const GETAPPROVALLIMITPROFILES = 'getapprovallimitprofiles';
export const GETMAPPEDUSERSTOAPPROVALLIMITPROFILE = 'getmappeduserstoapprovalLimitprofile'; //{profileid}
export const GETUNMAPPEDUSERSTOAPPROVALLIMITPROFILE = 'getunmappedusersapprovallimitprofile'; //{profileid}
export const CREATEORUPDATEAPPROVALLIMITPROFILE = 'createorupdateapprovallimitprofile';
export const APPROVEAPPROVALLIMITPROFILES = 'approveapprovallimitprofiles';
export const DELETEAPPROVALLIMITPROFILE = 'deleteapprovallimitprofiles';
export const MAPUSERSTOAPPROVALLIMITPROFILE = 'mapuserstoapprovallimitprofile';
export const UNMAPUSERSTOAPPROVALLIMITPROFILE = 'unmapusersfromapprovallimitprofile';
export const GETUNMAPPEDASSETCLASSTOAPPROVALLIMITPROFILE = 'getunmappedassetclassapprovallimitprofile'; //{profileid}
export const GETMAPPEDASSETCLASSTOAPPROVALLIMITPROFILE = 'getmappedassetclasstoapprovalLimitprofile'; // {profileid}
export const MAPASSETCLASSTOAPPROVALLIMITPROFILE = 'mapassetclasstoapprovallimitprofile';
export const UNMAPASSETCLASSTOAPPROVALLIMITPROFILE = 'unmapassetclassfromapprovallimitprofile';

//Client Withdrawal Request
export const GETPENDINGCLIENTTHIRDPARTYPAYMENTREQUESTS = 'getpendingclientthirdpartypaymentrequests';
export const CREATECLIENTTHIRDPARTYPAYMENTREQUESTS = 'createclientthirdpartypaymentrequests';
export const APPROVECLIENTTHIRDPARTYPAYMENTREQUESTS = 'approveclientthirdpartybeneficiarypaymentrequests';
export const INVALIDATECLIENTTHIRDPARTYPAYMENTREQUESTS = 'invalidateclientthirdpartypaymentrequests';
export const CREATEORUPDATECLIENTWITHDRWAL = 'createorupdateclientwithdarawal';
export const GETUNSUBMITTEDCLIENTWITHDRAWALS = 'getunsubmittedclientwithdrawals';
export const GETSUBMITTEDCLIENTWITHDRWAL4APPROVAL = 'getsubmittedclientwithdrawals4approval';
export const SUBMITCLIENTWITHDRWALS = 'submitclientwithdrawals';
export const GETCLIENTTHIRDPARTYBENEFICIARYPAYMENTREQUESTS = 'getclientthirdpartybeneficiarypaymentrequests';
export const APPROVECLIENTTHIRDPARTYBENEFICIARYPAYMENTREQUEST =
  'approveclientthirdpartybeneficiarypaymentrequests';
export const REJECTCLIENTWITHDRWALS = 'rejectclientwithdrawals';
export const APPROVECLIENTWITHDRWALS = 'approveclientwithdrawals';
export const VALIDATECLIENTWITHDRWALS = 'validateclientwithdrawals';
export const INVALIDATEPENDINGCLIENTWITHDRAWAL = 'invalidatependingclientwithdrawal';
export const GETWITHDRAWALJOURNALTYPEBYPORTFOLIO = 'getwithdrawaljournaltypebyportfolio';
export const GETAPPROVEDCLIENTWITHDRAWAL4SETTLEMENT = 'getclientwithdrawalsawaitingsettlement';
export const REVERSECLIENTWITHDRWALS = 'reverseclientwithdrawals';
export const PROCESSCLIENTWITHDRAWAL4SETTLEMENT = 'processclientwithdrawals4settlement';

export const CREATECLIENTS = 'createclients';
export const UPDATECLIENT = 'updateclient';
export const INVALIDATECLIENTS = 'invalidateclients';
export const CREATECSCS = 'createcscs';
export const UPDATECSCS = 'updatecscs';
export const DELETECSCS = 'deletecscs'; // /id
export const INVALIDATECLIENTUPDATE = 'invalidateclientupdate';

// CLIENT -- CRM
export const GETCLIENTSPENDINGWELCOMELETTER = 'getclientspendingwelcomeletter';
export const PUBLISHCLIENTWELCOMLETTER = 'publishclientwelcomeletter';
export const TESTCLEINTWELCOMELETTER = 'testclientwelcomeletter';
export const GETHTLMEMAILVARIABLES = 'gethtmlemailvariables';
export const GETCRMEMAILMESSAGEPROFILES = 'getcrmemailmessageprofiles';
export const CREATEORUPDATECRMEMAILMESSAGEPROFILES = 'createorupdatecrmemailmessageprofiles';
export const DELETECRMEMAILMESSAGEPROFILE = 'deletecrmemailmessageprofile';
export const GETCRMEMAILPROCESSTYPES = 'getcrmemailprocesstypes';
export const GETEMAILPROFILES = 'getemailprofiles';
export const GETONLINEPRODUCTS = 'getonlineproducts';
export const GETONLINEPRODUCTS4APPROVAL = 'getonlineproducts4approval';
export const APPROVEONLINEPRODUCTS = 'approveonlineproducts';
export const GETPORTFOLIOLISTFORONLINE = 'getportfoliolistforonline';
export const DELETEONLINEPRODUCTS = 'deleteonlineproducts';
export const GETONLINEPRODUCT = 'getonlineproduct'; //{transid}
export const CREATEORUPDATEONLINEPRODUCTS = 'createorupdateonlineproducts';
export const DISABLEONLINEPRODUCT = 'disableonlineproduct'; //{transid}
export const ENABLEORDISABLEONLINEPRODUCT = 'enableordisableonlineproduct'; //{transid}
export const GETRETAILPRODUCTS = 'getretailproducts';
export const GETRETAILPRODUCT = 'getretailproduct'; //{transid}
export const CREATEORUPDATERETAILPRODUCT = 'createorupdateretailproduct';
export const GETUNMAPPEDRETAILPRODUCTSECURITIES = 'getunmappedretailproductsecurities'; //{productid}
export const GETMAPPEDRETAILPRODUCTSECURITIES = 'getmappedretailproductsecurities'; //{productid}
export const MAPRETAILPRODUCTSECURITIES = 'mapretailproductsecurities';
export const UNMAPRETAILPRODUCTSECURITIES = 'unmapretailproductsecurities';
export const DEACTIVATEPRODUCTSTATUS = 'deactivateproductstatus'; //{productId}
export const UPDATERETAILPRODUCTSECURITYCOUNTERPARTYORBROKER =
  'updateretailproductsecuritycounterpartyorbroker'; //{transID} {productId}
export const GETRETAILPRODUCTTENOR = 'getretailproducttenor';
export const GETUNAPPROVEDRETAILPRODUCTS = 'getunapprovedretailproducts';
export const APPROVERETAILPRODUCTS = 'approveretailproducts';
export const CREATEORUPDATERETAILPRODUCTTENOR = 'createorupdateretailproducttenor';
export const GETCUSTOMERSTATEMENTPROFILES = 'getcustomerstatementprofiles';
export const CREATEORUPDATESTATEMENTPROFILE = 'createorupdatestatementprofile';
export const GETRELATIVEPERIODOPTIONTYPES = 'getrelativeperiodoptiontypes';
export const GETUNSUMMITTEDCUSTOMERSTATEMENTBATCHES = 'getunsumbittedcustomerstatementbatches';
export const CREATESTATEMENTBATCH = 'createstatementbatch';
export const INVALIDATESTATEMENTBATCH = 'invalidatestatementbatch'; //{batchno}
export const GETCUSTOMERSTATEMENTBATCHRECIPIENTS = 'getcustomerstatementbatchrecipients'; //{batchno}
export const GETPENDINGCUSTOMERSTATEMENTBATCHRECIPIENTS = 'getpendingcustomerstatementbatchrecipients'; //{batchno}
export const DOWNLOADCUSTOMERSTATEMENT = 'downloadcustomerstatement';
export const DOWNLOADAPPROVEDCUSTOMERSTATEMENT = 'downloadapprovedcustomerstatement';
export const DOWNLOADPENDINGCUSTOMERSTATEMENT = 'downloadpendingcustomerstatement';
export const GENERATECUSTOMERSTATEMENT = 'generatecustomerstatement'; //{batchno}
export const APPROVESTATEMENTBATCH = 'approvestatementbatch'; //{batchno}
export const GETSUBMITTEDSTATEMENTBATCHSAWAITINGAPPROVAL = 'getsumbittedstatementbatchesawaitingapproval';
export const GETAPPROVEDCUSTOMERSTATEMENTBATCHES = 'getapprovedcustomerstatementbatches';
export const GETAPPROVEDCUSTOMERSTATEMENTBATCHERECIPIENTS = 'getapprovedcustomerstatementbatchrecipients';
export const RESETSTAGINGCUSTOMERSTATEMENTS = 'resetstagingcustomerstatements';
export const RESETAPPROVEDCUSTOMERSTATEMENTS = 'resetapprovedcustomerstatements';
export const SUBMITCUSTOMERSTATEMENTBATCH = 'submitcustomerstatementbatch'; //{batchno}
export const REJECTSTATEMENTBATCH = 'rejectstatementbatch'; //{batchno}
export const DISABLECLIENTBANKACCOUNT = 'disableclientbankaccount'; //transId
export const GETCLIENTVIRTUALACCOUNTS = 'getclientvirtualaccounts'; //clientId

//Portfolio
export const CREATEWORKFLOWPROCESS = 'createworkflowprocess';
export const CREATEORUPDATEWORKFLOWMEMBERS = 'createorupdateworkflowmembers';
export const UPLOADWORKFLOWMEMBERSIGNATURE = 'uploadworkflowmembersignature';
// export const GETWORKFLOWPROFILES = 'getworkflowprofiles';
export const GETWORKFLOWRECIPIENTTYPES = 'getworkflowrecipienttypes';
export const GETWORKFLOWHTMLEMAILTEMPLATES = 'getworkflowhtmlemailtemplates';
export const GETWORKFLOWPERIODINFOCUSTYPES = 'getworkflowperiodinfocustypes';
export const GETWORKFLOWDIRECTIONTYPES = 'getworkflowdirectiontypes';
export const CREATEORUPDATEWORKFLOWPROFILE = 'createorupdateworkflowprofile';
export const GETWORKFLOWAUTOMATIONPROFILES = 'getworkflowautomationprofiles';
export const GETMAPPEDWORKFLOWAUTOMATION = 'getmappedworkflowautomation';
export const GETUNMAPPEDWORKFLOWAUTOMATION = 'getUnmappedWorkflowAutomation';
export const GETWORKFLOWPROFILEMEMBERS = 'getmappedworkflowprofilemembers';
export const UNMAPWORKFLOWPROFILEMEMBERS = 'getunmappedworkflowprofilemembers';
export const MAPWORKFLOWAUTOMATIONPROFILETOASSETCLASS = 'mapworkflowautomationprofiletoassetclass';
export const UNMAPWORKFLOWAUTOMATIONPROFILETOASSETCLASS = 'unmapworkflowautomationprofilefromassetclass';
export const DELETEWORKFLOWAUOMATIONPROFILE = 'deleteworkflowautomationprofile';
export const DISABLEORENABLEWORKFLOWAUTOMATIONPROFILE = 'disableorenableworkflowautomationprofile';
export const CREATEORUPDATEWORKFLOWAUOMATIONPROFILE = 'createorupdateworkflowautomationprofile';
export const GETWORKFLOWAUTOMATIONPROFILE = 'getworkflowautomationprofile';
export const GETREPORTPATHS = 'getreportpaths';
export const GETDOCUMENTREPOSITORY = 'getdocumentrepository';
export const GETCOUNTERPARTYEXPOSURELIMIT = 'getcounterpartyexposurelimit';
export const GETCOUNTERPARTYGLOBALEXPOSURELIMIT = 'getcounterpartyglobalexposurelimit';
export const CREATEUPDATEORDELETECOUNTERPARTYGLOBALLIMIT = 'createupdateordeletecounterpartygloballimit';
export const CREATEORUPDATEVALUATIONSCHEDULER = 'createorupdatevaluationscheduler';
export const GETACTIVEUSERS = 'getactiveusers';
export const SAVEAUTOVALUATIONSEQUENCE = 'saveautovaluationsequence';
export const GETVALUATIONBOTSCHEDULER = 'getvaluationbotscheduler';
export const GETVALUATIONBOTLOG = 'getvaluationbotlog'; //refrenceNo
export const CREATEORUPDATERMASREPORTITEMS = 'createorupdatermasreportitems';
export const GETRMASREPORTFREQUENCY = 'getrmasreportfrequency';
export const GETRMASREPORTLIST = 'getrmasreportlist'; //{frequency}
export const GETRMASASSETCLASSMAPPING = 'getrmasassetclassmapping';
export const UPDATERMASASSETCLASSMAPPING = 'updatermasassetclassmapping';
export const GETRMASREPORTASSOCIATION = 'getrmasreportassociation';
export const CREATEORUPDATERMASREPORTASSOCIATION = 'createorupdatermasreportassociation';
export const GETRMASSCHEMEASSOCIATIONMAPPING = 'getrmasschemeassociationmapping';
export const GETUNIQUERMASASSOCIATIONCODES = 'getuniquermasassociationcodes';
export const UPDATERMASSCHEMEASSOCIATIONMAPPING = 'updatermasschemeassociationmapping';
export const GETUNMAPPEDPORTFOLIOEXCEPTION = 'getunmappedportfolioexception';
export const GETMAPPEDPORTFOLIOEXCEPTION = 'getmappedportfolioexception';
export const MAPPORTFOLIOEXCEPTION = 'maportfolioexception';
export const UNMAPPORTFOLIOEXCEPTION = 'unmapportfolioexception';
export const GETPORTFOLIOEXCEPTIONSAWAITINGAPPROVAL = 'getportfolioexceptionsawaitingapproval';
export const APPROVEPORTFOLIOEXCEPTIONS = 'approveportfolioexceptions';
export const SETPORTFOLIOEXCEPTIONCONTROLDATE = 'setportfolioexceptioncontroldate';

//Security
export const GETAUDITTRAIL = 'getaudittrail';
export const GETLOGINTRAIL = 'getlogintrail';
export const GETMAPPEDGROUPUSER = 'getmappedgroupuser';
export const GETUNMAPPEDGROUPUSER = 'getunmappedgroupuser';
export const GETUSERDEPARTMENTS = 'getuserdepartments';
export const GETUSERGROUPS = 'getusergroups';
export const GETEMAILMESSAGEHISTORY = 'getemailmessagehistory';
export const GETCLIENTEMAILMESSAGEHISTORY = 'getclientemailmessagehistory';

export const MAPGROUPUSER = 'mapGroupUser';
export const UNMAPGROUPUSER = 'unmapgroupuser';
export const CREATEORUPDATEUSERDEPARTMENTS = 'createorupdateuserdepartments';
export const CREATEORUPDATEUSER = 'createorupdateuser';
export const GETMAPPEDNOTIFICATIONGROUP = 'getmappednotificationgroup';
export const GETUNMAPPEDNOTIFICATIONGROUP = 'getunmappednotificationgroup';
export const UNMAPNOTIFICATIONGROUP = 'unmapnotificationgroup';
export const MAPNOTIFICATIONGROUP = 'mapnotificationgroup';

export const LOGOUTUSER = 'logout';

//Reversal
export const GETREVERSALAWAITINGAPPROVAL = 'getreversalawaitingapproval';
export const GETREVERSALDIALOGUE = 'getreversaldialogue';

export const TRANSACTIONREVERSALAPPROVAL = 'transactionreversalapproval';
export const TRANSACTIONAPPROVALREF = 'transactionapprovalref';
export const INVALIDATEREVERSAL = 'invalidatereversal';
export const INITIATEREVERSAL = 'initiateReversal';
export const TRANSACTIONAPPROVAL = 'transactionapproval';
export const REJECTAPPROVAL = 'rejectapproval';
export const REJECTFIXEDINCOMETRANSACTIONS = 'rejectfixedincometransactons';
export const REJECTFIXDINCOMESELLTRANSACTIONS = 'rejectfixedincomeselltransactons';
export const REJECTCAPITALMARKETTRANSACTIONS = 'rejectcapitalmarkettransactions';

//Trade
export const GETTRANSAWAITINGAPPROVAL = 'gettransawaitingapproval'; // /type/id | /type
export const GETSUBMITTEDSTOCKSAWAITINGAPPROVAL = 'getsubmittedstocksawaitingapproval';
export const GETUNSUBMITTEDSTOCKS = 'getunsubmittedstocks';
export const GETSTOCKQUANTITYBALANCE = 'getstockquantitybalance'; //{schemeid}/{companyid}/{instrumentid};
export const GETSUBMITTEDMONEYMARKETLIQUIDATIONS = 'getsubmittedmoneymarketliquidations';
export const GETUNSUBMITTEDMONEYMARKETLIQUIDATIONS = 'getunsubmittedmoneymarketliquidations';
export const GETUNSUBMITTEDMONEYMARKETSELL = 'getunsubmittedmoneymarketsell'; // /type/id | /type
export const GETSUBMITTEDMONEYMARKETSELL = 'getsubmittedmoneymarketsell'; // /type/id | /type
export const GETUNSUBMITTEDMONEYMARKETTRANSACTIONS = 'getunsubmittedmoneymarkettransactions'; // /type/id | /type
export const GETSUBMITTEDMONEYMARKETTRANSACTIONS = 'getsubmittedmoneymarkettransactions'; // /type/id | /type
export const GETUNSUBMITTEDBONDTRANSACTIONS = 'getunsubmittedbondtransactions'; // /type/id | /type
export const GETSUBMITTEDBONDTRANSACTIONS = 'getsubmittedbondtransactions'; // /type/id | /type
export const GETMUTUALFUNDDIVIDENDAWAITINGAPPROVAL = 'getmutualfunddividendawaitingapproval'; // /type/id | /type
export const GETLIABILITYPRODUCTS = 'getliabilityproducts'; // /type/id | /type
export const GETLIABILITYPLACEMENTSAWAITINGAPPROVAL = 'getliabilityplacementsawaitingapproval'; // /type/id | /type
export const GETPORTFOLIOBANKS = 'getportfoliobanks'; // /schemeid/'A' | 'C' | 'I' | '' = ''
export const GETPORTFOLIOBANKSFROMLIABILITYPRODUCT = 'getportfoliobanksfromliabilityproduct'; // /schemeid/'A' | 'C' | 'I' | '' = ''

export const GETPORTFOLIOINSTRUMENTS = 'getportfolioinstruments'; // /schemeid/type
export const GETPORTFOLIOCSCS = 'getportfoliocscs'; // /id /scope
export const GETSHORTTERMINSTITUTIONS = 'getshortterminstitutions'; // /instrumentId
export const GETLIABILITYPRODUCTSECURITIES = 'getliabilityproductsecurities'; // /instrumentId
export const INVALIDATECASHLIABILITY = 'invalidatecashliability';
export const SUBMITCASHLIABILITIES = 'submitcashliabilities';
export const REJECTCASHLIABILITIES = 'rejectcashliabilities';
export const INVALIDATEFIXEDINCOMETRANS = 'invalidatefixedincometrans';
export const INVALIDATEFIXEDINCOMESELLTRANS = 'invalidatefixedincomeselltrans';
export const SUBMITFIXEDINCOMESELLTRANSACTIONS = 'submitfixedincomeselltransactons';
export const INVALIDATESTOCKTRANS = 'invalidatestocktrans';
export const CREATEMONEYMARKETPLACEMENTS = 'createmoneymarketplacements';
export const CREATEFIXEDINCOMEDISPOSAL = 'createFixedIncomeDisposal';
export const VALIDATELIQUIDATEBONDONCOUPONANNIVERSARY = 'validateliquidatebondoncouponanniversary';
export const LIQUIDATEBONDONCOUPONANNIVERSARY = 'liquidatebondoncouponanniversary';
export const CREATESTOCKTRANS = 'createstocktrans';
export const CREATEBONDPLACEMENTS = 'createbondplacements';
export const UPDATEMONEYMARKETPLACEMENT = 'updatemoneymarketplacement';
export const UPDATEFIXEDINCOMESALE = 'updateFixedIncomeSale';
export const UPDATESTOCKTRANS = 'updatestocktrans';
export const UPDATEBONDPLACEMENT = 'updatebondplacement';
export const VALIDATEMONEYMARKETPLACEMENTS = 'validatemoneymarketplacements';
export const VALIDATEFIXEDINCOMESALE = 'validateFixedIncomeSale';
export const VALIDATESTOCKTRANS = 'validatestocktrans';
export const VALIDATEBONDPLACEMENTS = 'validatebondplacements';
export const GETFIXEDINCOMESALESAWAITINGAPPROVAL = 'getfixedincomesalesawaitingapproval'; // /transId
export const GETFINANCIALINSTRUMENTFROMASSETCLASS = 'getfinancialinstrumentfromassetclass'; // /assetclass
export const GETASSETCLASSINSTITUTIONS = 'getassetclassinstitutions'; // /id
export const INVALIDATEACCRUEDDIVIDEND = 'invalidateaccrueddividend';
export const ACCRUEDIVIDENDUPLOAD = 'accrueDividendUpload';
export const ACCRUEDIVIDEND = 'accruedividend';
export const DECLAREBONUS = 'declarebonus';
export const SUBMITFIXEDINCOMETRANSACTIONS = 'submitfixedincometransactons';
export const SUBMITCAPITALMARKETTRANSACTIONS = 'submitcapitalmarkettransactions';

//Real Estate
export const GETUNSUBMITTEDREALESTATEBUYSELL = 'getunsubmittedrealestatebuysell'; // transtype = B OR S
export const GETSUBMITTEDREALESTATEBUYSELL = 'getsubmittedrealestatebuysell'; // transtype = B OR S
export const GETUNSUBMITTEDREALESTATEREVALUATION = 'getunsubmittedrealestaterevaluation';
export const GETSUBMITTEDREALESTATEREVALUATION = 'getsubmittedrealestaterevaluation';
export const GETUNSUBMITTEDRENTRECEIVED = 'getunsubmittedrentreceived';
export const GETSUBMITTEDRENTRECEIVED = 'getsubmittedrentreceived';
export const GETPENDINGRENTRECEIVED = 'getpendingrentreceived';
export const CREATEORUPDATEREALESTATERENTRECEIVABLE = 'createorupdaterealestaterentreceivable';
export const CREATEORUPDATEREALESTATERENTRECEIVED = 'createorupdaterealestaterentreceived';
export const SUBMITREALESTATETRANSACTIONS = 'submitrealestatetransactions';
export const SUBMITREALESTATEREVALUATION = 'submitrealestaterevaluation';
export const SUBMITREALESTATERENTRECEIVED = 'submitrealestaterentreceived';
export const CREATEORUPDATEREALESTATEBUY = 'createorupdaterealestatebuy';
export const CREATEORUPDATEREALESTATESELL = 'createorupdaterealestatesell';
export const CREATEORUPDATEREALESTATEREVALUATION = 'createorupdaterealestaterevaluation';
export const APPROVEREALESTATEBUYANDSELL = 'approverealestatebuyandsell';
export const APPROVEREALESTATEREVALUATION = 'approverealestaterevaluation';
export const APPROVEREALESTATERENTRECEIVED = 'approverealestaterentreceived';
export const REJECTREALESTATETRANSACTIONS = 'rejectrealestatetransactions';
export const REJECTREALESTATERENTRECEIVED = 'rejectrealestaterentreceived';
export const INVALIDATEREALESTATETRANSACTIONS = 'invalidaterealestatetransactions';
export const INVALIDATEREALESTATERENTRECEIVED = 'invalidaterealestaterentreceived';
export const INVALIDATEREALESTATEREVALUATION = 'invalidaterealestaterevaluation';
export const GETPENDINGREALESTATEPROPERTY = 'getpendingrealestateproperty';
export const GETREALESTATEPROPERTIESAWAITINGAPPROVAL = 'getrealestatepropertiesawaitingapproval';

// export const ACCRUEMUTUALFUNDDIVIDEND = 'accruemutualfunddividend';
export const DECLAREMUTUALFUNDDIVIDEND = 'declaremutualfunddividend';
export const GETDIVIDENDACCRUED4SELECTION = 'getdividendaccrued4selection'; // ?/schemeId
export const GETOBJECTIVEINSTRUMENTS = 'getobjectiveinstruments';
export const GETINTERESTPAYMENTPROFILES = 'getinterestpaymentprofiles';
export const GETCUSTOMERSFROMPRODUCT = 'getcustomersfromproduct';
export const GETINTERESTPAYMENTPROFILEAMOUNTFLAG = 'getinterestpaymentprofileamountflag';
export const GETPAYMENTINTERESTAMOUNTTYPES = 'getpaymentintestamounttypes';
export const CREATEORUPDATEINTERESTPAYMENTPROFILES = 'createorupdateinterestpaymentprofiles';
export const APPROVECOUPONONBOND = 'approvecoupononbond';

//FinIns
export const GETFINANCIALINSTRUMENTBYTYPE = 'getfinancialinstrumentbytype'; // /type
export const GETINSTITUTIONSFROMFINANCIALINSTRUMENT = 'getinstitutionsfromfinancialinstrument'; // /assetClass
export const GETDAYCOUNTMODELTYPES = 'getdaycountmodeltypes';
export const GETFINANCIALINSTRUMENTTYPES = 'getfinancialinstrumenttypes';
export const GETFINANCIALINSTRUMENTS = 'getfinancialinstruments';
export const UPDATEFINANCIALINSTRUMENTSEQUENCE = 'updatefinancialinstrumentsequence';
export const UPDATEAMORTFINANCIALINSTRUMENT = 'updateamortfinancialinstrument';
export const SETUPAMORTIZATIONINSTRUMENT = 'setupamortizationinstrument';
export const GETGLENTRIES = 'getglentries'; // /type /process
export const SETUPOTHERINSTRUMENT = 'setupotherinstrument';
export const SETUPMANAGEMENTFEEINSTRUMENT = 'setupmanagementfeeinstrument';
export const SETUPBONDPRODUCT = 'setupbondproduct';
export const SETUPMONEYMARKETINSTRUMENT = 'setupmoneymarketinstrument';
export const SETUPBONDINSTRUMENT = 'setupbondinstrument';
export const SETUPOTHERASSETINSTRUMENT = 'setupotherassetinstrument';
export const SETUPSTOCKMARKETINSTRUMENT = 'setupstockmarketinstrument';
export const UPDATEFINANCIALINSTRUMENTOTHERS = 'updatefinancialinstrumentothers';
export const UPDATEMUTUALFUNDPRODUCT = 'updatemutualfundproduct';
export const UPDATEMONEYMARKETFINANCIALINSTRUMENT = 'updatemoneymarketfinancialinstrument';
export const UPDATEBONDFINANCIALINSTRUMENT = 'updatebondfinancialinstrument';
export const UPDATESTOCKFINANCIALINSTRUMENT = 'updatestockfinancialinstrument';
export const CREATEORUPDATEREALESTATEFINANCIALINSTRUMENT = 'createorupdaterealestatefinancialinstrument';
export const UPDATEOTHERASSETSFINANCIALINSTRUMENT = 'updateotherassetsfinancialinstrument';
export const GETFIXEDINCOMESELECTION = 'getfixedincomeselection'; // /type
export const APPROVEPORTFOLIOS = 'approveportfolios'; //
export const APPROVEOTHERASSETTRANSACTIONS = 'approveotherassettransactions'; //
export const APPROVEOTHERASSETREVALUATION = 'approveotherassetrevaluation'; //
export const APPROVEMANAGEMENTFEEPAYMENT = 'approvemanagementfeepayment'; //
export const APPROVEMANAGEMENTFEEADJUSTMENT = 'approvemanagementfeeadjustment'; //
export const GETBONDSECURITYAWAITINGAPPROVAL = 'getbondsecurityawaitingapproval'; //
export const GETCOMMERCIALPAPERISSUES = 'getcommercialpaperissues'; //
export const GETCOMMERCIALPAPERSECURITIESAWAITINGAPPROVAL = 'getcommercialpapersecuritiesawaitingapproval'; //
export const CREATEORUPDATECOMMERCIALPAPERSECURITY = 'createorupdatecommercialpapersecurity'; //
export const GETSTOCKSECURITYAWAITINGAPPROVAL = 'getstocksecurityawaitingapproval'; //
export const GETMUTUALFUNDSECURITYAWAITINGAPPROVAL = 'getmutualfundsecurityawaitingapproval'; //
export const GETBANKACCOUNTPROFILES = 'getbankaccountprofiles'; //
export const GETBANKACCOUNTPROFILE = 'getbankaccountprofile'; //transId
export const GETBANKACCOUNTSWITHSTP = 'getbankaccountswithstp';
export const GETBANKACCOUNTSWITHNOSTP = 'getbankaccountswithnostp';
export const MAPBANKACCOUNTSTOSTP = 'mapbankaccountstostp';
export const UNMAPBANKACCOUNTSFROMSTP = 'unmapbankaccountsfromstp';
export const CREATEORUPDATEBANKACCOUNTPROFILES = 'createorupdatebankaccountprofiles';
export const GETUNMAPPEDPORTFOLIOSTOBANKACCOUNT = 'getUnmappedportfoliostobankaccount'; //accountNo
export const GETMAPPEDPORTFOLIOSTOBANKACCOUNT = 'getmappedportfoliostobankaccount'; //accountNo
export const MAPPORTFOLIOSTOBANKACCOUNT = 'mapportfoliostobankaccount';
export const GETPORTFOLIOBANKOVERDRAFT = 'getportfoliobankoverdraft';
export const CREATEPORTFOLIOBANKOVERDRAFT = 'createportfoliobankoverdraft';
export const GETPORTFOLIOBANKOVERDRAFTAWAITINGAPPROVAL = 'getportfoliobankoverdraftawaitingapproval';
export const GETBANKACCOUNTPENDINGSTP = 'getbankaccountpendingstraightthroughprocessing';
export const APPROVEBANKACCOUNTSTP = 'approvebankaccountstraightthroughprocessing';
export const APPROVALPORTFOLIOBANKOVERDRAFT = 'approveportfoliobankoverdraft';
export const UNMAPPORTFOLIOSFROMBANKACCOUNT = 'unmapportfoliosfrombankaccount';
export const DISABLEPORTFOLIOBANKACCOUNT = 'disableportfoliobankaccount'; // transid
export const ENABLEPORTFOLIOBANKACCOUNT = 'enableportfoliobankaccount'; // transid
export const GETBANKACCOUNTPROFILEAWAITINGAPPROVAL = 'getbankaccountprofileawaitingapproval';
export const APPROVEBANKACCOUNTPROFILES = 'approvebankaccountprofiles';
export const GETPORTFOLIOBANKSAWAITNGAPPROVAL = 'getportfoliobanksawaitingapproval';
export const APPROVEPORTFOLIOBANKACCOUNTMAPPING = 'approveportfoliobankaccountmapping';
export const GETPORTFOLIOACTIVECHARTOFACCOUNT = 'getportfolioactivechartofaccount'; //schemeId

//STP AUthorization

export const GETMAPPEDVERIFIERS = 'getmappedverifiers';
export const GETUNMAPPEDVERIFIERS = 'getunmappedverifiers';
export const MAPUSERSTOVERIFYER = 'mapuserstoverifier';
export const UNMAPUSERSFROMVERIFYER = 'unmapusersfromverifyer';
export const GETVERIFIERAWAITINGAPPROVAL = 'getverifiersawaitingapproval';
export const APPROVEVERIFIERS = 'approveverifiers';

export const CREATEORUPDATESIGNATORY = 'createorupdatesignatory';
export const GETSIGNATORIES = 'getsignatories';
export const GETMAPPEDSIGNATORYUSERS = 'getmappedsignatoryusers';
export const GETUNMAPPEDSIGNATORYUSERS = 'getunmappedsignatoryusers';
export const MAPUSERSTOSIGNATORY = 'mapuserstosignatory';
export const UNMAPUSERFROMSIGNATORY = 'unmapusersfromsignatory';
export const GETSIGNATORYUSERSAWAITINGAPPROVAL = 'getsignatoryusersawaitingapproval';
export const APPROVESIGNATORYUSERS = 'approvesignatoryusers';

export const GETMAPPEDAUTHORIZER1SIGNATORIES = 'getmappedauthorizer1signatories';
export const GETUNMAPPEDAUTHORIZER1SIGNATORIES = 'getunmappedauthoriser1signatories';
export const MAPSIGNATORIESTOAUTHORIZER1 = 'mapsignatoriestoauthoriser1';
export const UNMAPSIGNATORIESFROMAUTHORIZER1 = 'unmapsignatoriesfromauthoriser1';
export const GETAUTHORIZER1SIGNATORIESAWAITINGAPPROVAL = 'getauthoriser1signatoriesawaitingapproval';
export const APPROVEAUTHORIZER1SIGNATORIES = 'approveauthoriser1signatories';

export const PROCESSCLIENTWITHDRAWALSETTLEMENT = 'processclientwithdrawalsettlement';
export const PROCESSCLIENTWITHDRAWALSVERIFICATION = 'processclientwithdrawalsverification';
export const PROCESSCLIENTWITHDRAWALSAUTHORIZER1 = 'processclientwithdrawalsauthoriser1';
export const PROCESSCLIENTWITHDRAWALSAUTHORIZER2 = 'processclientwithdrawalsauthoriser2';

export const INITIATECLIENTWITHDRAWALAUTHORISER12FA = 'initiateclientwithdrawalauthoriser12fa';
export const INITIATECLIENTWITHDRAWALAUTHORISER22FA = 'initiateclientwithdrawalauthoriser22fa';
export const PROCESSCLIENTWITHDRAWALAUTHORISER1VIA2FA = 'processclientwithdrawalsauthoriser1via2fa';
export const PROCESSCLIENTWITHDRAWALAUTHORISER2VIA2FA = 'processclientwithdrawalsauthoriser2via2fa';
export const GETTOKENEDCLIENTWITHDRAWALAWAITINGAUTHORISER = 'gettokenedclientwithdrawalsawaitingauthoriser';
export const PROCESSCLIENTWITHDRAWALSAUTHORISERVIA2FA = 'processclientwithdrawalsauthoriservia2fa';
export const GETTOKENEDCLIENTWITHDRAWALSAWAITINGAUTHORISER1 =
  'gettokenedclientwithdrawalsawaitingauthoriser1';
export const GETTOKENEDCLIENTWITHDRAWALSAWAITINGAUTHORISER2 =
  'gettokenedclientwithdrawalsawaitingauthoriser2';

export const REJECTCLIENTWITHDRAWALVERIFICATION = 'rejectclientwithdrawalsverification';
export const REJECTCLIENTWITHDRAWALSAUTHORIZER1 = 'rejectclientwithdrawalsauthoriser1';
export const REJECTCLIENTWITHDRAWALSAUTHORIZER2 = 'rejectclientwithdrawalsauthoriser2';

export const GETCLIENTWITHDRAWALSAWAITINGSETTLEMENT = 'getclientwithdrawalsawaitingsettlement';
export const GETCLIENTWITHDRAWALSAWAITINGVERIFICATION = 'getclientwithdrawalsawaitingverification';
export const GETCLIENTWITHDRAWALSAWAITINGAUTHORIZER1 = 'getclientwithdrawalsawaitingauthoriser1';
export const GETCLIENTWITHDRAWALSAWAITINGAUTHORIZER2 = 'getclientwithdrawalsawaitingauthoriser2';

export const GETMAPPEDAUTHORIZER2SIGNATORIES = 'getmappedauthoriser2signatories';
export const GETUNMAPPEDAUTHORIZER2SIGNATORIES = 'getunmappedauthoriser2signatories';
export const MAPSIGNATORIESTOAUTHORIZER2 = 'mapsignatoriestoauthoriser2';
export const UNMAPSIGNATORIESFROMAUTHORIZER2 = 'unmapsignatoriesfromauthoriser2';
export const GETAUTHORIZER2SIGNATORIESAWAITINGAPPROVAL = 'getauthoriser2signatoriesawaitingapproval';
export const APPROVEAUTHORIZER2SIGNATORIES = 'approveauthoriser2signatories';
export const LOGINUSERUSING2FA = 'login'; //:token

//Other basic
export const GETCASHCLASSBYINSTRUMENT = 'getcashclassbyinstrument';
export const GETLIABILITYCASHCLASSBYPORTFOLIO = 'getliabilitycashclassbyportfolio';
export const GETDEPOSITLIABILITYCASHCLASSBYPORTFOLIO = 'getdepositliabilitycashclassbyportfolio';
export const GETWITHDRAWALLIABILITYCASHCLASSBYPORTFOLIO = 'getwithdrawalliabilitycashclassbyportfolio';
export const GETCASHCLASSBYPORTFOLIO = 'getcashclassbyportfolio';
export const GETCASHCLASS4GLBYINSTRUMENT = 'getcashclass4glbyinstrument';
export const GETFEEBATCHPAYMENTSCHEDULE = 'getfeebatchpaymentschedule';
export const GETPORTFOLIOFEETYPES = 'getportfoliofeetypes';
export const GETINTERESTSCHEDULEPAYMENTSDUE = 'getinterestschedulepaymentsdue';
export const UPDATEDAILYPENSIONCONTRIBUTION = 'middleware/updatedailypensioncontribution';
export const APPROVEPENSIONCONTRIBUTIONREDEMPTION = 'approvepensioncontributionredemption';
export const GETPENSIONCONTRIBUTIONREDEMPTIONAWAITINGAPPROVAL =
  'getpensioncontributionredemptionawaitingapproval';
export const INITIATESCHEDULEDINTERESTPAYMENT = 'initiatescheduledinterestpayment';
export const GETINTERESTPAYMENTSAWAITINGAPPROVAL = 'getinterestpaymentsawaitingapproval';
export const APPROVESCHEDULEDINTERESTPAYMENT = 'approvescheduledinterestpayment';
export const REJECTSCHEDULEDINTERESTPAYMENT = 'rejectscheduledinterestpayment';
export const APPROVEPROCESSINGDATECHANGEREQUEST = 'approveprocessingdatechangerequest';
export const APPROVEPORTFOLIODNOTDEBIT = 'approveportfoliodonotdebit';
export const APPROVECASHJOURNALS = 'approvecashjournals';
export const APPROVEAMORTIZATIONANDCAPITALIZATION = 'approveamortizationandcapitalization';
export const APPROVEPENDINGMONEYMARKETPRIORITYROLLOVERRATE = 'approvemoneymarketpriorityrolloverrate';
export const CREATEORUPDATEMONEYMARKETPRIORITYROLLOVERRATE = 'createorupdatemoneymarketpriorityrolloverrate';
export const INVALIDATEMONEYMARKETPRIORITYROLLOVERRATE = 'invalidatemoneymarketpriorityrolloverrate';
export const GETPENDINGMONEYMARKETPRIORITYROLLOVERRATE = 'getpendingmoneymarketpriorityrolloverrate'; //transid
export const GETCAPITALCASHINFLOW = 'getcapitalcashinflow';
export const GETCAPITALCASHOUTFLOW = 'getcapitalcashoutflow';
export const GETUNSUBMITTEDMONEYMARKETINSTANTROLLOVER = 'getunsubmittedmoneymarketinstantrollover';
export const GETSUBMITTEDMONEYMARKETINSTANTROLLOVER = 'getsubmittedmoneymarketinstantrollover';
export const GETUNSUBMITTEDLIABILITYPRODUCTROLLOVER = 'getunsubmittedliabilityproductrollover';
export const GETSUBMITTEDLIABILITYPRODUCTROLLOVER = 'getsubmittedliabilityproductrollover';

export const GETUNSUBMITTEDFIXEDINCOMEINTERFUNDSALE = 'getunsubmittedfixedincomeinterfundsale';
export const GETSUBMITTEDFIXEDINCOMEINTERFUNDSALE = 'getsubmittedfixedincomeinterfundsale';
export const INVALIDATEFIXEDINCOMEINTERFUNDSALE = 'invalidateinterfundfixedincomesale';
export const REJECTFIXEDINCOMEINTERFUNDSALE = 'rejectinterfundfixedincomesale';
export const APPROVEFIXEDINCOMEINTERFUNDSALE = 'approveinterfundfixedincomesale';
export const CREATEORUPDATEFIXEDINCOMEINTERFUNDSALE = 'createorupdateinterfundfixedincomesale';
export const GETSINGLEFIXEDINCOMEINTERFUNDSALE = 'getsinglefixedincomeinterfundsale';
export const GETRECLASSIFYFIXEDINCOMEDESTINATIONINSTRUMENTS = 'getreclassifyfixedincomedestinationinstruments'; 
export const VALIDATEFIXEDINCOMEINTERFUNDSALE = 'validateinterfundfixedincomesale';


export const APPROVEMONEYMARKETINSTANTROLLOVER = 'approvemoneymarketinstantrollover';
export const APPROVELIABILITYPRODUCTROLLOVER = 'approveliabilityproductrollover';

export const REJECTMONEYMARKETINSTANTROLLOVER = 'rejectmoneymarketinstantrollover';
export const GETPENDINGWITHOLDINGTAX4PAYMENT = 'getpendingwitholdingtaxdue4payment';
export const GETPENDINGWITHOLDINGTAXBYBATCH = 'getpendingwitholdingtaxbybatch';
export const PAYWITHOLDINGTAX = 'paywitholdingtax';

export const CREATEORUPDATEFEETRANSACTION = 'createorupdatefeetransaction';
export const VALIDATEFEETRANSACTION = 'validatefeetransaction';
export const CREATEORUPDATECAPITALISEDINTEREST = 'createorupdatecapitalisedinterest';
export const CREATEORUPDATECASHENTRY = 'createorupdatecashentry';
export const CREATEORUPDATEACCOUNTINGUNITS = 'createorupdateaccountingunits';
export const CREATEORUPDATEBANKENTRIES = 'createorupdateinterbankentries';

export const GETUNSUBMITTEDCAPITALCASHFLOWJOURNALS = 'getunsubmittedcapitalcashflowjournals';
export const GETSUBMITTEDCAPITALCASHFLOWJOURNALS = 'getsubmittedcapitalcashflowjournals';
export const SUBMITCASHJOURNALENTRIES = 'submitcashjournalentries';
export const REJECTCASHJOURNALENTRIES = 'rejectcashjournalentries';


//InterBank Transfer Charges
export const GETINTERBANKTRANSFERCHARGES = 'getinterbanktransfercharges';
export const CREATEORUPDATEINTERBANKTRANSFERCHARGES = 'createorupdateinterbanktransfercharges';
export const DELETEINTERBANKTRANSFERCHARGES = 'deletetinterbanktransfercharges';
export const MAPBANKACCOUNTTOINTERBANKTRANSFERCHARGES = 'mapbankaccounttotnterbanktransfercharges';
export const UNMAPBANKACCOUNTTOINTERBANKTRANSFERCHARGES = 'unmapbankaccountfrominterbanktransfercharges';
export const GETMAPPEDBANKACCOUNTTOINTERBANKTRANFERCHARGES = 'getmappedbankaccounttointerbanktransfercharges'; //{chargecode}
export const GETUNMAPPEDBANKACCOUNTFROMINTERBANKTRANSFERCHARGES =
  'getunmappedbankaccountfrominterbanktransfercharges'; //{chargecode}
export const GETPENDINGMAPPEDBANKACCOUNTTOINTERBANKTRANSFERCHARGES =
  'getpendingmappedbankaccounttointerbanktransfercharges';
export const APPROVEINTERBANKTRANSFERCHARGESTOBANKACCOUNT = 'approveinterbanktransferchargestoBankaccount';

//Corporate
export const INVALIDATETRANSAWAITINGAPPROVAL = 'invalidatetransawaitingapproval'; // /type
export const INVALIDATEMUTUALFUNDDIVIDEND = 'invalidatemutualfunddividend'; // /refrenceNo
export const APPROVEMUTUALFUNDDIVIDEND = 'approvemutualfunddividend'; // /refrenceNo
export const APPROVEMUTUALFUNDDIVIDENDS = 'approvemutualfunddividends'; // /refrenceNo
export const RECEIVEBULKDIVIDEND = 'receivebulkdividend';
export const CREATEORUPDATERECEIVEDDIVIDEND = 'createorupdatereceiveddividend';
export const APPROVEACCRUEDSTOCKDIVIDEND = 'approveaccruedstockdividend';
export const APPROVERECIEVEDSTOCKDIVIDEND = 'approvereceivedstockdividend';
export const APPROVESTOCKBONUS = 'approvestockbonus';
export const GETMUTUALFUNDDIVIDENDDUEFORPAYMENT = 'getmutualfunddividenddueforpayment';
export const INITIATEMUTUALFUNDDIVIDENDPAYMENT = 'initiatemutualfunddividendpayment';
export const APPROVEMUTUALFUNDDIVIDENDPAYMENT = 'approvemutualfunddividendpayment';
export const GETMUTUALFUNDDIVIDENDPAYMENTAWAITINGAPPROVAL = 'getmutualfunddividendpaymentawaitingapproval';

//Table
export const GETUSERPORTFOLIOS = 'getuserportfolios';
export const GETASSETLIABILITYUSERPORTFOLIOS = 'getuserassetliabilityportfolios';

export const GETCURRENCIES = 'getcurrencies';
export const GETVATLIST = 'getvatlist';
export const GETWITHHOLDINGTAXLIST = 'getwithholdingtaxlist';
export const GETCUSTODIANS = 'getcustodians';
export const GETCUSTODIANBANKS = 'getcustodianbanks';
export const CREATEORUPDATECUSTODIANS = 'createorupdatecustodians';
export const CREATEORUPDATECUSTODIANBANK = 'createorupdatecustodianbank';
export const GETOBJECTIVES = 'getobjectives';
export const GETPORTFOLIOS = 'getportfolios';
export const GETASSETCLASSGROUPS = 'getassetclassgroups';
export const GETASSETCLASSTYPES = 'getassetclasstypes';
export const DELETEASSETCLASSGROUP = 'deleteassetclassgroup'; // /id
export const DELETESECURITYSETUP = 'deletesecuritysetup'; // /id
export const GETVALSUMCATEGORY = 'getvalsumcategory';
export const GETVALUATIONMETHODS = 'getvaluationmethods';
export const GETTBILLTYPES = 'gettbilltypes';
export const CREATEUPDATEASSETCLASSGROUP = 'createupdateassetclassgroup';
export const GETPENDINGPORTFOLIOS = 'getpendingportfolios';
export const DELETEUNAPPROVEDPORTFOLIOS = 'deleteunapprovedportfolios';
export const GETGLENTITIES = 'getglentities';
export const GETGLBATCHESBYCREATEDDATERANGE = 'getglbatchesbycreateddaterange'; //{companyid}/{startdate}/{enddate}
export const GETGLBATCHENTRIES = 'getglbatchentries'; //{companyid}/{batchno}
export const CREATEGLREVERSALBATCHFROMEXISTINGBATCH = 'createglreversalbatchfromexistingbatch'; //{transid}
export const CREATEGLREVERSALENTRIESFROMEXISTINGENTRIES = 'createglreversalentriesfromexistingentries';
export const GETGLPERIODBALANCES = 'getglperiodbalances';
export const REBUILDACCOUNTBALANCES = 'rebuildaccountbalances';
export const REBUILDALLACCOUNTBALANCES = 'rebuildallaccountbalances';
export const GETPORTFOLIOGROUPS = 'getportfoliogroups';
export const SETUPPORTFOLIOS = 'setupportfolios';
export const GETPENDINGPORTFOLIO = 'getpendingportfolio'; // /id
//export const GETDEPARTMENTS = 'getdepartments'; // /id
export const UPDATEPORTFOLIO = 'updateportfolio';
export const UPDATEPENDINGPORTFOLIO = 'updatependingportfolio';

export const GETPORTFOLIO = 'getportfolio'; // /id
export const GETRISKPROFILE = 'getriskprofile'; // /id
export const GETOBJECTIVE = 'getobjective'; // /id
export const GETRISKPROFILES = 'getriskprofiles';
export const GETMAPPEDFINANCIALINSTRUMENT = 'getmappedfinancialinstrument'; // /id
export const GETUNMAPPEDFINANCIALINSTRUMENT = 'getunmappedfinancialinstrument'; // /id
export const GETMAPPEDFEEEXCLUSIONASSETCLASS = 'getmappedfeeexclusionassetclass'; // /id
export const GETUNMAPPEDFEEEXCLUSIONASSETCLASS = 'getunmappedfeeexclusionassetclass'; // /id
export const GETCSCS = 'getcscs';
export const GETBIDOFFERPROFILE = 'getbidofferprofiles';
export const GETBIDOFFERPARAMETERS = 'getbidofferparameters'; // id
export const CREATEORUPDATECSCS = 'createorupdatecscs';
export const CREATEORUPDATEBIDOFFERPROFILES = 'createorupdatebidofferprofile';
export const CREATEORUPDATEBIDOFFERPARAMETER = 'createorupdatebidofferparameter';
export const SETUPTBILLTYPE = 'setuptbilltype';
export const SETUPPORTFOLIOGROUP = 'setupportfoliogroup';
export const SETUPCURRENCY = 'setupcurrency';
export const SETUPOBJECTIVE = 'setupobjective';
export const SETUPRISKPROFILE = 'setupriskprofile';
export const SETUPMAPFINANCIALINSTRUMENT = 'setupmapFinancialinstrument';
export const SETUPUNMAPFINANCIALINSTRUMENT = 'setupunmapFinancialinstrument';
export const MAPASSETCLASSTOFEEEXCLUSION = 'mapassetclasstofeeexclusion';
export const UNMAPASSETCLASSFROMFEEEXCLUSION = 'unmapassetclassfromfeeexclusion';
export const APPROVEASSETCLASSFEEEXCLUSION = 'approveassetclassfeeexclusion';
export const GETPORTFOLIOFEEEXCLUSIONFORAPPROVAL = 'getportfoliofeeexclusionforapproval';
export const GETPORTFOLIOBANKBALANCE = 'getportfoliobankbalance'; // /bankaccountno/schemeid

export const SETUPVALSUMCATEGORY = 'setupvalsumcategory';
export const UPDATETBILLTYPE = 'updatetbilltype';
export const UPDATEPORTFOLIOGROUP = 'updateportfoliogroup';
export const UPDATECURRENCY = 'updatecurrency';
export const UPDATERISKPROFILE = 'updateriskprofile';
export const UPDATEOBJECTIVE = 'updateobjective';
export const UPDATEVALSUMCATEGORY = 'updatevalsumcategory';
export const DELETETBILLTYPE = 'deletetbilltype'; // /id
export const DELETEVALSUMCATEGORY = 'deletevalsumcategory'; // /id
export const DELETEPORTFOLIOGROUP = 'deleteportfoliogroup'; // /id
export const DELETEOBJECTIVE = 'deleteobjective'; // /id
export const DELETERISKPROFILE = 'deleteriskprofile'; // /id
export const DELETEBIDOFFERPROFILE = 'deletebidofferprofile'; // id
export const DELETEBIDOFFERPARAMETER = 'deletebidofferparameter'; // id
export const CREATEORUPDATEVAT = 'createorupdatevat';
export const CREATEORUPDATEWITHHOLDINGTAX = 'createorupdatewithholdingtax';

//Institution
export const GETCASHCLASS = 'getcashclass';
export const GETALLCAPITALMARKETINSTITUTIONS = 'getallcapitalmarketinstitutions';
export const GETALLSECURITIES = 'getallsecurities';
export const GETCOUNTERPARTIES = 'getcounterparties';
export const GETINSTITUTIONS = 'getinstitutions';
export const GETCOUNTERPARTYLIST = 'getcounterpartylist';
export const GETFIXEDINCOMECHARGETYPES = 'getfixedincomechargetypes';
export const GETBONDS = 'getbonds';
export const GETBONDPAYMENTFREQUENCYTYPES = 'getbondpaymentfrequencytypes';
export const SETUPINDUSTRYSUBSECTOR = 'setupindustrysubsector';
export const UPDATEINDUSTRYSUBSECTOR = 'updateindustrysubsector';
export const GETAPPROVEDANDPENDINGBONDSECURITIES = 'getapprovedandpendingbondsecurities';
export const GETBONDPROVISIONTYPES = 'getbondprovisiontypes';
export const GETISSUERS = 'getissuers';
export const GETTRANSACTIONCHARGES = 'gettransactioncharges';
export const GETTRANSACTIONCHARGETYPES = 'gettransactionchargetypes';
export const GETASSETCLASSGROUPBYFINANCIALINSTRUMENTTYPE = 'getassetclassgroupbyfinancialinstrumenttype'; // /type
export const GETINDUSTRYSECTORS = 'getindustrysectors';
export const GETINDUSTRYSUBSECTORS = 'getIndustrySubsectors';
export const GETAPPROVEDANDPENDINGINSTITUTIONS = 'getapprovedandpendinginstitutions';
export const GETREALESTATEPROPERTIES = 'getrealestateproperties';
export const GETALLREALESTATEPROPERTIES = 'getallrealestateproperties';
export const INVALIDATESECURITYSETUP = 'invalidatesecuritysetup';
export const CREATEORUPDATEREALESTATEPROPERTY = 'createorupdaterealestateproperty';
export const GETREALESTATEPROPERTYTYPES = 'getrealestatepropertytypes';
export const GETMUTUALFUNDS = 'getmutualfunds';
export const GETAPPROVEDANDPENDINGMUTUALFUNDS = 'getapprovedandpendingmutualfunds';
export const GETMUTUALFUND = 'getmutualfund';
export const GETSTATES = 'getstates';
export const GETHOLIDAYCALENDAR = 'getholidaycalendar';
export const SETUPISSUER = 'setupissuer';
export const SETUPINDUSTRYSECTOR = 'setupindustrysector';
export const SETUPTRANSACTIONCHARGE = 'setuptransactioncharge';
export const SETUPCASHCLASS = 'setupcashclass';
export const SETUPSECURITYINSTITUTION = 'setupsecurityinstitution';
export const SETUPMUTUALFUNDPRODUCT = 'setupmutualfundproduct';
export const SETUPSTATE = 'setupstate';
export const SETUPHOLIDAYCALENDAR = 'setupholidaycalendar';
export const SETUPBROKER = 'setupbroker';
export const SETUPCOUNTERPARTY = 'setupcounterparty';
export const SETUPFIXEDINCOMECHARGETYPE = 'setupfixedincomechargetype';
export const UPDATEISSUER = 'updateissuer';
export const UPDATEBONDPRODUCT = 'updatebondproduct';
export const UPDATEINDUSTRYSECTOR = 'updateindustrysector';
export const UPDATETRANSACTIONCHARGE = 'updatetransactioncharge';
export const UPDATECASHCLASS = 'updatecashclass';
export const UPDATESECURITYINSTITUTION = 'updatesecurityinstitution';
export const UPDATESECURITYSYMBOL = 'updatesecuritysymbol';
export const UPDATESTATE = 'updatestate';
export const UPDATEHOLIDAYCALENDAR = 'updateholidaycalendar';
export const UPDATEBROKER = 'updatebroker';
export const UPDATECOUNTERPARTY = 'updatecounterparty';
export const UPDATEFIXEDINCOMECHARGETYPE = 'updatefixedincomechargetype';
export const DELETEHOLIDAYCALENDAR = 'deleteholidaycalendar';
export const APPROVEINSITUTIONSPRODUCTSANDSECURITIES = 'approveinstitutionsproductsandsecurities';
export const CALCULATEBONDPAYMENTDATES = 'calculatebondpaymentdates';
export const GETISSUERAMORTIZATIONSCHEDULE = 'getissueramortizationschedule';
export const UPLOADISSUERPAYMENTSCHEDULE = 'uploadbondIssuerpaymentschedule';

//Order
export const GETSINGLESTOCKORDERDETAILFORUPDATE = 'getsinglestockorderdetailforupdate'; // /id
export const GETFINANCIALINSTRUMENTFROMINSTITUTION = 'getfinancialinstrumentfrominstitution'; // /id
export const VALIDATESTOCKORDERTRANS = 'validatestockordertrans';
export const CREATEORUPDATESTOCKORDER = 'createorupdatestockorder';
export const INVALIDATEORDERAWAITINGAPPROVAL = 'invalidateorderawaitingapproval'; // /type
export const CREATEBUYMONEYMARKETPREORDERANALYSIS = 'createbuymoneymarketpreorderanalysis';
export const GETSTOCKORDERDETAILFORVIEW = 'getstockorderdetailforview'; // /id
export const GETMUTUALFUNDDIVIDENDSCHEDULE = 'getmutualfunddividendschedule'; // /refrenceNO
export const RESETSTOCKPREORDERANALYSIS = 'resetstockpreorderanalysis'; // /id
export const RESETMONEYMARKETPREORDERANALYSIS = 'resetmoneymarketpreorderanalysis'; // /id
export const SAVESTOCKPREORDERANALYSIS = 'savestockpreorderanalysis'; // /id
export const SAVEMONEYMARKETPREORDERANALYSIS = 'savemoneymarketpreorderanalysis'; // /id
export const MARKSTOCKPREORDERANALYSIS4SAVE = 'markstockpreorderanalysis4save';
export const MARKMONEYMARKETPREORDERANALYSIS4SAVE = 'markmoneymarketpreorderanalysis4save';
export const CREATESTOCKPREORDERANALYSIS = 'createstockpreorderanalysis';
export const CREATESELLMONEYMARKETPREORDERANALYSIS = 'createsellmoneymarketpreorderanalysis';
export const VALIDATESTOCKPREORDERANALYSIS = 'validatestockpreorderanalysis';
export const VALIDATEMONEYMARKETPREORDERANALYSIS = 'validatemoneymarketpreorderanalysis';
export const DELETESTOCKPREORDERANALYSIS = 'deletestockpreorderanalysis';
export const SETEQUITYJUSTIFICATION = 'setequityorderjustification';
export const GETQUITYJUSTIFICATION = 'getequityorderjustification'; //{referenceNo/itemId};
export const DELETEMONEYMARKETPREORDERANALYSIS = 'deletemoneymarketpreorderanalysis';
export const APPROVESTOCKORDER = 'approvestockorder';
export const APPROVEMONEYMARKETORDER = 'approvemoneymarketorder';
export const GETUNSUBMITTEDMONEYMARKETORDERS = 'getunsubmittedmoneymarketorders';
export const GETSUBMITTEDMONEYMARKETORDERAWAITINGAPPROVAL = 'getsubmittedmoneymarketorderawaitingapproval';
export const INVALIDATEFIXEDINCOMEORDERS = 'invalidatefixedincomeorders';
export const SUBMITFIXEDINCOMEORDERS = 'submitfixedincomeorders';
export const APPROVEFIXEDINCOMEORDER = 'approvefixedincomeorder';
export const CREATEMONEYMARKETBUYORDERS = 'createmoneymarketbuyorders';
export const CREATEMONEYMARKETSellORDERS = 'createmoneymarketsellorders';
export const REJECTFIXEDINCOMEORDERS = 'rejectfixedincomeorders';
export const GETUNSUBMITTEDFIXEDINCOMEORDER = 'getunsubmittedfixedincomeorder'; //{referenceNo}
export const GETUNSUBMITTEDBONDORDERS = 'getunsubmittedbondorders';
export const CREATEBONDBUYORDERS = 'createbondbuyorders';
export const CREATEBONDSELLORDERS = 'createbondsellorders';
export const GETSUBMITTEDBONDORDERAWAITINGAPPROVAL = 'getsubmittedbondorderawaitingapproval';

//Expected Credit Loss
export const GETECLLOCALCREDITRATINGBYDATE = 'getecllocalcreditratingbydate'; //{date}
export const GETECLGLOBALSCALEMAPPINGBYDATE = 'geteclglobalscalemappingbydate'; //{date}
export const GETECLPROBABILITYOFDEFAULTBYDATE = 'geteclprobabilityofdefaultbydate'; //{date}
export const GETECLRECOVERYRATESBYDATE = 'geteclrecoveryratesbydate'; //{date}
export const GETECLMACROECONOMICSCENARIOSBYDATE = 'geteclmacroeconomicscenariosbydate'; //{date}
export const GETECLPREVIEWBYDATE = 'previewecldata'; //{date}
export const PREVIEWECLDATA = 'previewecldata';
export const CREATEORUPDATEECLLOCALRATES = 'createorupdateecllocalrates';
export const CREATEORUPDATEECLGLOBALSCALEMAPPING = 'createorupdateeclglobalscalemapping';
export const CREATEORUPDATEECLPROBABILITYOFDEFAULT = 'createorupdateeclprobabilityofdefault';
export const CREATEORUPDATEECLRECOVERYRATES = 'createorupdateeclrecoveryrates';
export const CREATEORUPDATEECLMACROECONOMICSCENARIOS = 'createorupdateeclmacroeconomicscenarios';
export const DELETEECLLOCALRATES = 'deleteecllocalrates';
export const DELETEECLGLOBALRATES = 'deleteeclglobalrates';
export const DELETEECLPROBABILITYOFDEFAULT = 'deleteeclprobabilityofdefault';
export const DELETEECLRECOVERYRATES = 'deleteeclrecoveryrates';
export const DELETEECLMACROECONOMICSCENARIOS = 'deleteeclmacroeconomicscenarios';
export const GETMACROECONOMICFACTORS = 'getmacroeconomicfactors';
export const CREATEORUPDATEECLMACROECONOMICSCENARIOSFACTORS = 'createOrupdatemacroeconomicfactors';
export const DELETEMACROECONOMICFACTORS = 'deleteeclmacroeconomicfactor'; // {transId};
export const MAPPORTFOLIOTOECLEXCEPTION = 'mapportfoliostoeclexception';
export const UNMAPPORTFOLIOFROMECLEXCEPTION = 'unmapportfoliosfromeclexception';
export const GETMAPPPEDPORTFOLIOTOECLEXCEPTION = 'getmappedportfoliostoeclexception';
export const GETUNMAPPEDPORTFOLIOFROMECLEXCEPTION = 'getunmappedUnportfoliosfromeclexception';

//General Ledger
export const GETBROKERS = 'getbrokers';
export const GETORDEREXPIRIES = 'getorderexpiries'; // ?id
export const GETGLPROFILES = 'getglprofiles';
export const SETUPPORTFOLIOPUBLISHSTARTDATE = 'setportfoliopublishstartdate';
export const GETINTEGRATIONPROFILEPORTFOLIOS = 'getintegrationprofileportfolios';
export const GETUNMAPPEDINTEGRATIONPORTFOLIOS = 'getunmappedintegrationportfolios';
export const REMOVEPORTFOLIOSFROMINTEGRATIONPROFILE = 'removeportfoliosfromintegrationprofile';
export const MAPPORTFOLIOSTOINTERATIONPROFILE = 'mapportfoliostointegrationprofile';
export const MAPPORTFOLIOSTOINTEGRATIONPROFILE = 'mapportfoliostointegrationprofile';
export const GETGLINTEGRATIONPROFILES = 'getglintegrationprofiles';
export const GETFUNDINTEGRATIONSTATUS = 'getfundintegrationstatus';
export const UPDATEFUNDINTEGRATIONSTATUS = 'updatefundintegrationstatus';
export const DELETEGLINTEGRATIONPROFILE = 'deleteglintegrationprofile';
export const GETASSETCLASS4ACCOUNTINGRULE = 'getassetclass4accountingrule';
export const GETASSETCLASS4INTEGRATIONACCOUNTINGRULE = 'getassetclass4integrationaccountingrule';
export const GETACCOUNTINGRULES = 'getaccountingrules';
export const GETINTEGRATIONACCOUNTINGRULES = 'getintegrationaccountingrules';
export const GETRULEPROCESSORAMOUNTTYPE = 'getruleprocessoramounttype';
export const GETGLFUNDACCOUNTS4DROPDOWNDISPLAY = 'getglfundaccounts4dropdowndisplay';
export const PREVIEWINTEGRATIONACOUNTINGENTRIES = 'previewintegrationaccountingentries'; //profileId/instrumentId
export const GETGLACCOUNTS4DROPDOWNDISPLAY = 'getglaccounts4dropdowndisplay';
export const DELETEACCOUNTINGRULES = 'deleteaccountingrules';
export const DELETEINTEGRATIONACCOUNTINGRULES = 'deleteintegrationaccountingrules';
export const CREATEORUPDATECASHACCOUNTINGRULES = 'createorupdatecashaccountingrules';
export const CREATEORUPDATEINTEGRATIONACCOUNTINGRULES = 'createorupdateintegrationaccountingrules';
export const CREATEORUPDATEACCOUNTINGRULES = 'createorupdateaccountingrules';
export const CREATEORUPDATEDATECHANGEREQUEST = 'createorupdatedatechangerequest';
export const GETGLSCHEDULESETUPBYPROFILEID = 'getglschedulesetupbyprofileid';
export const CREATEORUPDATEGLSCHEDULESETUP = 'createorupdateglschedulesetup';
export const DELETEGLSCHEDULESETUPENTRIES = 'deleteglschedulesetupentries';
export const GETGLSCHEDULESETUPENTRIES = 'getglschedulesetupentries';
export const CREATEORUPDATEGLSCHEDULESETUPENTRIES = 'createorupdateglschedulesetupentries';
export const CREATEWITHDRAWALRESTRICTION = 'createwithdrawalrestriction';
export const REMOVEWITHDRAWALRESTRICTION = 'removewithdrawalrestriction';
export const GETGLJOURNALENTRIESBYDEPARTMENT = 'getgljournalentriesbydepartment';
export const GETGLJOURNALENTRIES = 'getgljournalentries';
export const GETMMFRECAPITALISEDINTERESTAWAITINGAPPROVAL = 'getmmfrecapitalisedinterestawaitingapproval';
export const GETMMFRECAPITALISEDINTERESTDETAILS = 'getmmfrecapitalisedinterestdetails';
export const GETMULTIPLEENDOFDAYDATA = 'getmultipleendofdaydata';
export const GETMULTIPLEEODAWAITINGAPPROVAL = 'getmultipleeodawaitingapproval';
export const GETRECURRINGTEMPLATESFORAPPROVAL = 'getrecurringtemplatesforapproval';
export const APPROVERECURRINGTEMPLATE = 'approverecurringtemplate';
export const GETRECONCILIATIONBATCHES = 'getreconciliationbatches';
export const GETRECONCILIATIONBANKACCOUNTS = 'getreconciliationbankaccounts';
export const CREATEORUPDATERECONCILIATIONBATCH = 'createorupdatereconciliationbatch';
export const GETRECONCILIATIONDATA = 'getreconciliationdata'; // /groupid/table
export const GETCASHBOOKRECONCILIATIONDATA = 'getcashbookreconciliationdata'; // /groupid/table
export const GETBANKRECONILIATIONDATA = 'getbankreconciliationdata'; // /accountNumber/table
export const GETNONRECONCILIATIONBANKACCOUNTS = 'getnonreconciliationbankaccounts'; // /accountNumber/table
export const ADDBANKACCOUNTTORECONCILIATION = 'addbankaccounttoreconciliation'; // /accountNumber/table
export const MOCKBANKRECONCILIATIONDATAUPLOAD = 'mockbankreconciliationdataupload'; // /accountNumber/table
export const UPLOADRECONCILIATIONDATA = 'uploadreconciliationdata';
export const SETRECONCILIATIONBANKACCOUNTSTARTDATE = 'setreconciliationbankaccountstartdate';
export const SETRECONCILIATIONBANKACCOUNTENDDATE = 'setreconciliationbankaccountenddate';
export const SETRECONCILIATIONBANKACCOUNTCUTOFFDATE = 'setreconciliationbankaccountcutoffdate';
export const GETRECONCILIATIONPERIODOPTIONS = 'getreconciliationperiodoptions';
export const CREATERECONCILIATIONPROFILES = 'createreconciliationprofiles';
export const GETRECONCILIATIONENDOFPERIODAWAITINGAPPROVAL = 'getreconciliationendofperiodawaitingapproval';
export const APPROVEENDOFRECONCILIATIONPERIOD = 'approveendofreconciliationperiod';
export const TOGGLEACCOUNTHIDEMATCHED = 'toggleaccounthidematched';
export const GETHIDEMATCHEDSTATUS = 'gethidematchedstatus';
export const SETBANKACCOUNTSORT = 'setbankaccountsort';
export const SETRECONCILIATIONESCALATION = 'setreconciliationescalation';
export const MATCHRECORDS = 'matchrecords';
export const UNMATCHRECORDS = 'unmatchrecords';
export const ENDRECONCILIATIONPERIODREQUEST = 'endreconciliationperiodrequest';
export const PARIALMATCHRECORDS = 'partialmatchrecords';
export const GETPARTIALLYMATCHEDDATA = 'getpartiallymatcheddata';
export const GETHISTORICALLYMATCHEDDATA = 'gethistoricallymatcheddata';
export const AUTOMATCHRECORDS = 'automatchrecords';
export const CONTRAMATCHRECORDS = 'contramatchrecords';
export const UPLOADBANKRECONCILIATIONDATA = 'uploadbankreconciliationdata';
export const UPLOADBANKRECONCILIATIONDIRECTDATA = 'uploadbankreconciliationdirectdata';
export const DELETERECONCILIATIONRECORDS = 'deletereconciliationrecords';
export const CREATERECONCILIATIONCOMMENT = 'createreconciliationcomment';
export const GETBANKACCOUNTS = 'getbankaccounts';
export const GETBANKS = 'getbanks';
export const GETSTPBANKS = 'getstpbanks';
export const GETALLACTIVECHARTOFACCOUNT = 'getallactivechartofaccount';
export const SETUPBANKACCOUNT = 'setupbankaccount';
export const UPDATEBANKACCOUNT = 'updatebankaccount';
export const GETBANKBALANCEMODES = 'getbankbalancemodes';
export const GETWALLETACCOUNTS = 'getwalletaccounts';
export const GETWALLETACCOUNT = 'getwalletaccount';
export const GETWALLETPROFILES = 'getwalletprofiles';
export const GETWALLETPROFILE = 'getwalletprofile';
export const GETWALLETPROCESSES = 'getwalletprocesses';
export const GETWALLETPROCESSESTYPES = 'getwalletprocesstypes';
export const CREATEORUPDATEWALLETACCOUNTS = 'createorupdatewalletaccounts';
export const CREATEORUPDATEWALLETPORFILE = 'createorupdatewalletprofile';
export const CREATEORUPDATEWALLETPROCESS = 'createorupdatewalletprocess';
export const GETCAPITALISEDINTERESTPROFILES = 'getcapitalisedinterestprofiles';
export const GETCAPITALISEDINTERESTDATES = 'getcapitalisedinterestdates';
export const CREATECAPITALISEDINTERESTRATEDATE = 'createcapitalisedinterestratedate'; //${capitalisedinterestprofileid}/${effectiveDate}
export const GETSINGLECAPITALISEDINTERESTPROFILE = 'getcapitalisedinterestprofile';
export const GETCAPITALISEDINTERESTRATE = 'getcapitalisedinterestrates';
export const CREATEORUPDATECAPITALISEDINTERESTPROFILES = 'createorupdatecapitalisedinterestprofiles';
export const CREATEORUPDATECAPITALISEDINTERESTRATE = 'createorupdatecapitalisedinterestrate';
export const EXCLUDECLIENTSFROMCAPITALISEDINTERESTS = 'excludeclientsfromcapitalisedinterests';
export const CANCELEXCLUDECLIENTSFROMCAPITALISEDINTERESTS = 'cancelexcludeclientsfromcapitalisedinterests';
export const GETCAPITALISEDINTERESTEXCLUDEDCLIENTS = 'getcapitalisedinterestexcludedclients'; //${capitalisedProfileId}
export const GETGLPERIODLIST4SELECTION = 'getglperiodlist4selection'; //${id}
export const GETGLJOURNALENTRIESAWAITINGAPPROVAL = 'getgljournalentriesawaitingapproval'; //${groupid}
export const GETGLJOURNALBATCHESAWAITINGAPPROVAL = 'getgljournalbatchesawaitingapproval'; //${companyid}
export const GETTEMPLATEFORMULARDATA = 'gettemplateformulardata'; //${transid}
export const GETUNMAPPEDREPORTTEMPLATEACCOUNTS = 'getunmappedreporttemplateaccounts'; //${priId}/${repId}
export const GETMAPPEDREPORTTEMPLATEACCOUNTS = 'getMappedReportTemplateAccounts'; //${priId}/${repId}/${itemId}
export const GETGLREPORTTEMPLATEITEMTYPES = 'getglreporttemplateitemtypes';
export const GETGLREPORTTEMPLATEFONTSTYLE = 'getglreporttemplatefontstyle';
export const GETGLREPORTTEMPLATEEFFECTS = 'getglreporttemplateeffects';
export const GETGLREPORTTEMPLATEITEMNATURE = 'getglreporttemplateitemnature';
export const GETGLREPORTTEMPLATEITEMS = 'getglreporttemplateitems'; //${prId}/${repId}
export const GETGLREPORTTEMPLATE = 'getglreporttemplate'; //${prId}/${type}
export const GETRMASREPORTLIST4GLDROPDOWN = 'getrmasreportlist4gldropdown';
export const GETGLSCHEDULESETUPBYCOMPANYID = 'getglschedulesetupbycompanyid'; //${compId}
export const GETGLPROFILETYPES = 'getglprofiletypes';
export const GETGLPERIODS = 'getglperiods';
export const GETGLACCOUNTCATEGORIES = 'getglaccountcategories'; //${id}
export const GETGLACCOUNTCATEGORYTYPES = 'getglaccountcategorytypes'; //${type}
export const GETGLBALANCESHEETSECTIONS = 'getglbalancesheetsections';
export const GETGLCASHFLOWSECTIONS = 'getglcashflowsections';
export const GETACTIVEPORTFOLIOS = 'getactiveportfolios';
export const GETCHARTOFACCOUNT = 'getchartofaccount'; //${id}
export const GETCHARTOFACCOUNTHEADERS4DROPDOWN = 'getchartofaccountheaders4dropdown'; //${id}
export const GETCHARTOFACCOUNTHEADERS = 'getchartofaccountheaders'; //${id}

export const GETGLRECURRINGPOSTINGHEADERS = 'getglrecurringpostingheaders'; //${id}
export const GETGLRECURRINGPOSTINGENTRIES = 'getglrecurringpostingentries'; //${id}
export const GETCHARTOFACCOUNTBYCOMPANYID = 'getchartofaccountbycompanyid'; //${id}
export const GETGLSCHEDULETRANSAWAITINGAPPROVAL = 'getglscheduletransawaitingapproval'; //?${id}
export const GETJOURNALBATCHESFORAPPROVAL = 'getjournalbatchesforapproval';
export const GETUNPOSTEDJOURNALBATCHENTRIES = 'getunpostedjournalbatchentries'; //${id}
export const GETRETAINEDEARNINGACCOUNTS = 'getretainedearningaccounts'; //${id}
export const GETPOSTEDENTRIES = 'getpostedentries';
export const GETTRIALBALANCE = 'gettrialbalance';
export const GETTRIALBALANCEBYDEPARTMENT = 'gettrialbalancebydepartment';
export const GETPOSTEDBATCHES = 'getpostedbatches';
export const GETHISTORICALPUBLISHEDJOURNALS = 'middleware/gethistoricalpublishedjournals'; //startdate/enddate
export const GETALLUNPOSTEDBATCHES = 'getallunpostedbatches';
export const GETALLUNPOSTEDJOURNALENTRIES = 'getallunpostedjournalentries';
export const GETGLENTITYDIALOGUEPARAMETER = 'getglentitydialogueparameter'; //${id}
export const GETGLPUBLISHPROFILES = 'getglpublishprofiles'; //${id}
export const DELETEGLPUBLISHPROFILES = 'deleteglpublishprofiles'; //${id}
export const CREATEORUPDATEPUBLISHPROFILES = 'createorupdateglpublishprofiles'; //${id}
export const INVALIDATEGLSCHEDULETRANS = 'invalidateglscheduletrans';
export const ENDOFPERIOD = 'endofperiod';
export const CREATEORUPDATEGLSCHEDULETRANSACTIONS = 'createorupdateglscheduletransactions';
export const CREATEORUPDATEGLJOURNALENTRIES = 'createorupdategljournalentries';
export const CREATEORUPDATEGLJOURNALBATCHES = 'createorupdategljournalbatches';
export const MAPFORMULARTOGLREPORTTEMPLATEITEM = 'mapformulartoglreporttemplateitem';
export const MAPGLACCOUNTTOREPORTITEM = 'mapglaccounttoreportitem';
export const UNMAPGLACCOUNTFROMREPORTITEM = 'unmapglaccountfromreportitem';
export const CREATEORUPDATEGLREPORTTEMPLATEITEMS = 'createorupdateglreporttemplateitems';
export const CREATEORUPDATEGLREPORTTEMPLATE = 'createorupdateglreporttemplate';
export const CREATEGLENTITY = 'createglentity';
export const CREATEGLPROFILE = 'createglprofile';
export const CREATEORUPDATEGLINTEGRATIONPROFILE = 'createorupdateglintegrationprofile';
export const SETUPORDEREXPIRY = 'setuporderexpiry';
export const CREATEGLACCOUNTCATEGORY = 'createglaccountcategory';
export const CREATECHARTOFACCOUNT = 'createchartofaccount';
export const CREATEORUPDATECHARTOFACCOUNTHEADERS = 'createorupdatechartofaccountheaders';
export const DELETECHARTOFACCOUNTHEADERS = 'deletechartofaccountheaders';
export const DELETECHARTOFACCOUNTS = 'deletechartofaccounts';
export const UPDATEGLENTITY = 'updateglentity';
export const UPDATEGLPROFILE = 'updateglprofile';
export const UPDATEORDEREXPIRY = 'updateorderexpiry';
export const UPDATEGLACCOUNTCATEGORY = 'updateglaccountcategory';
export const UPDATECHARTOFACCOUNT = 'updatechartofaccount';
export const CREATEORUPDATEGLRECURRINGHEADERS = 'createorupdateglrecurringheaders';
export const CREATEORUPDATEGLRECURRINGENTRIES = 'createorupdateglrecurringentries';
export const POSTJOURNALS = 'postjournals';
export const UNSUBMITJOURNALBATCHESFROMAPPROVAL = 'unsubmitjournalbatchesfromapproval';
export const SUBMITJOURNALBATCHES4APPROVAL = 'submitjournalbatches4approval';
export const DELETEGLJOURNALENTRIES = 'deletegljournalentries';
export const DELETEGLJOURNALBATCHES = 'deletegljournalbatches';
export const DELETEGLRECURRINGENTRIES = 'deleteglrecurringentries';
export const DELETEGLREPORTTEMPLATES = 'deleteglreporttemplates';
export const DELETEGLREPORTTEMPLATEITEMS = 'deleteglreporttemplateitems';

//GL Departments
export const GETGLDEPARTMENTS = 'getgldepartments'; // {companyId}
export const CREATEGLDEPARTMENT = 'creategldepartment';
export const GETSINGLEGLDEPARTMENT = 'getgldepartments'; // {transId}

//benchmark
export const GETASSETCLASSLIMITS = 'getassetclasslimits'; // /id
export const DELETEASSETCLASSLIMIT = 'deleteassetclasslimit';
export const CREATEORUPDATEASSETCLASSLIMIT = 'createorupdateassetclasslimit';
export const GETMAPPEDASSETCLASSLIMITS = 'getmappedassetclasslimits'; // /id/groupid
export const GETUNMAPPEDASSETCLASSLIMITS = 'getunmappedassetclasslimits'; // /id
export const MAPINSTRUMENTSTOASSETCLASSLIMIT = 'mapinstrumentstoassetclasslimit';
export const UNMAPINSTRUMENTSFROMASSETCLASSLIMIT = 'unmapinstrumentsfromassetclasslimit';
export const GETCOMPLIANCERULESCOPETYPES = 'getcompliancerulescopetypes';
export const GETCOMPLIANCERULELIMITYPES = 'getcompliancerulelimitypes';
export const GETCOMPLIANCERULES = 'getcompliancerules';
export const DELETECOMPLIANCERULES = 'deletecompliancerules';
export const CREATEORUPDATECOMPLIANCERULES = 'createorupdatecompliancerules';
export const GETMAPPEDCOMPLIANCERULEASSETCLASSES = 'getmappedcomplianceruleassetclasses'; // /referenceno
export const GETUNMAPPEDCOMPLIANCERULEASSETCLASSES = 'getunmappedcomplianceruleassetclasses'; // /referenceno
export const MAPASSETCLASSTOCOMPLIANCERULE = 'mapassetclasstocompliancerule';
export const UNMAPASSETCLASSFROMCOMPLIANCERULE = 'unmapassetclassfromcompliancerule';
export const GETMAPPEDCOMPLIANCERULEOBJECTIVES = 'getmappedcomplianceruleobjectives';
export const GETUNMAPPEDCOMPLIANCERULEOBJECTIVES = 'getunmappedcomplianceruleobjectives';
export const MAPOBJECTIVESTOCOMPLIANCERULE = 'mapobjectivestocompliancerule';
export const UNMAPOBJECTIVESFROMCOMPLIANCERULE = 'unmapobjectivesfromcompliancerule';
export const GETCREDITRISKAGENCIES = 'getcreditriskagencies';
export const GETCREDITAGENCYRISKCODES = 'getcreditagencyriskcodes'; // /code
export const GETRISKRATINGLIMITS = 'getriskratinglimits'; // /id
export const GETGROUPEDRISKRATINGCODES = 'getgroupedriskratingcodes';
export const GETMAPPEDCREDITRISKRATINGINSTITUTIONS = 'getmappedcreditriskratinginstitutions'; // /ref
export const GETUNMAPPEDCREDITRISKRATINGINSTITUTIONS = 'getunmappedcreditriskratinginstitutions'; // /rf/type
export const GETMAPPEDCREDITRISKLIMIT = 'getmappedcreditrisklimit'; // /objectiveId/groupId
export const GETUNMAPPEDCREDITRISKLIMIT = 'getunmappedcreditrisklimit'; // /objectiveId/groupId
export const GETREPORTPRESENTATIONPROFILES = 'getreportpresentationprofiles';
export const GETREPORTPRESENTATIONPROFILEITEMS = 'getreportpresentationprofileitems'; // /id
export const GETMAPPEDREPORTPRESENTATIONINSTRUMENTS = 'getmappedreportpresentationinstruments'; // /id /refno
export const GETUNMAPPEDREPORTPRESENTATIONINSTRUMENTS = 'getunmappedreportpresentationinstruments'; // /id
export const MAPINSTITUTIONSTOAGENCYRATINGCODE = 'mapinstitutionstoagencyratingcode';
export const UNMAPINSTITUTIONSFROMAGENCYRATINGCODE = 'unmapinstitutionsfromagencyratingcode';
export const MAPINSTRUMENTSTORISKRATINGLIMIT = 'mapinstrumentstoriskratinglimit';
export const UNMAPINSTRUMENTSFROMRISKRATINGLIMIT = 'unmapinstrumentsfromriskratinglimit';
export const CREATEORUPDATERISKRATINGLIMIT = 'createorupdateriskratinglimit';
export const CREATEORUPDATECREDITRISKRATINGAGENCIES = 'createorupdatecreditriskratingagencies';
export const CREATEORUPDATECREDITRISKRATINGCODES = 'createorupdatecreditriskratingcodes';
export const UPDATECREDITRATINGCODEEXPIRY = 'updatecreditratingcodeexpiry'; // /id
export const GETMODELPORTFOLIO = 'getmodelportfolio'; // /id
export const GETMAPPEDMODELPORTFOLIOITEMS = 'getmappedmodelportfolioitems'; // /id /groupid
export const GETMODELPORTFOLIOITEMSECURITY = 'getmodelportfolioitemsecurity'; // /id /groupid /instrumrntid
export const CREATEORUPDATEMODELPORTFOLIOS = 'createorupdatemodelportfolios';
export const UPDATEMODELPORTFOLIOALLOCATION = 'updatemodelportfolioallocation'; // /transid /allocation
export const GETBENCHMARKLIST = 'getbenchmarklist';
export const GETUNMAPPEDMODELPORTFOLIOITEMS = 'getunmappedmodelportfolioitems'; // /id
export const MAPINSTRUMENTSTOMODELPORTFOLIO = 'mapinstrumentstomodelportfolio';
export const GETINSTITUTIONSFROMINSTRUMENTID = 'getInstitutionsfrominstrumentid'; // /id
export const CREATEORUPDATEPORTFOLIOMODELSECURITY = 'createorupdateportfoliomodelsecurity';
export const UNMAPINSTRUMENTSFROMMODELPORTFOLIO = 'unmapinstrumentsfrommodelportfolio';
export const CREATEUPDATEREPORTPRESENTATIONPROFILES = 'createupdatereportpresentationprofiles';
export const CREATEUPDATEREPORTPRESENTATIONPROFILEITEMS = 'createupdatereportpresentationprofileitems';
export const MAPINSTRUMENTSTOPRESENTATIONITEM = 'mapinstrumentstopresentationitem';
export const UNMAPINSTRUMENTSFROMPRESENTATIONITEM = 'unmapinstrumentsfrompresentationitem';
export const GETISSUEREXPOSURELIMIT = 'getissuerexposurelimit'; // /id
export const GETSECTOREXPOSURELIMIT = 'getsectorexposurelimit'; // /id
export const GETBENCHMARKSETUPLIST = 'getbenchmarksetuplist';
export const GETBENCHMARKDATA = 'getbenchmarkdata'; // /code /type
export const GETBENCHMARKSOURCEDATA4SELECTION = 'getbenchmarksourcedata4selection';
export const CREATEUPDATEORDELETEISSUEREXPOSURELIMIT = 'createupdateordeleteissuerexposurelimit';
export const CREATEUPDATEORDELETESECTOREXPOSURELIMIT = 'createupdateordeletesectorexposurelimit';
export const CREATEUPDATEBENCHMARKS = 'createupdatebenchmarks';
export const CREATEUPDATEBENCHMARKDIRECTDATA = 'createupdatebenchmarkdirectdata';
export const CREATEUPDATEBENCHMARKMIXEDDATA = 'createupdatebenchmarkmixeddata';
export const DELETECREDITRATINGCODES = 'deletecreditratingcodes';
export const DELETEREPORTPRESENTATIONPROFILES = 'deletereportpresentationprofiles';
export const DELETEREPORTPRESENTATIONITEMS = 'deletereportpresentationitems';
export const DELETEMODELPORTFOLIOSECURITIES = 'deletemodelportfoliosecurities';
export const DELETEBENCHMARKS = 'deletebenchmarks';
export const DELETEBENCHMARKDATA = 'deletebenchmarkdata'; // /deltype
export const DELETEMODELPORTFOLIOS = 'deletemodelportfolios';
export const CREATEUPDATEORDELETECOUNTERPARTYEXPOSURELIMIT = 'createupdateordeletecounterpartyexposurelimit';

//random
export const GETSECURITYBYTYPE = 'getsecuritybytype'; // /type
export const GETUSERREPORTS = 'getuserreports';
export const GETPORTFOLIOCLIENTS = 'getportfolioclients'; //schemeId
export const GETOTHERASSETBALANCE4SELECTION = 'getotherassetbalance4selection'; //schemeId
export const GETPORTFOLIOQUERY = 'getportfolioquery';
export const MUTUALFUNDDIVIDENDBATCH = 'mutualfunddividendbatch';
export const GETENABLEDRMASREPORTLIST = 'getenabledrmasreportlist'; //{frequency}

// end of day
export const GETPREEODRECORDS = 'getpreeodrecords';
export const INITIATEEOD = 'initiateeod';
export const GETEODAWAITINGAPPROVAL = 'geteodawaitingapproval';
export const APPROVEEOD = 'approveeod';
export const REJECTEOD = 'rejecteod';

//fixed income
export const CREATEORUPDATEAMORTIZATION = 'createorupdateamortization';
export const CREATEORUPDATEOTHERASSETLIABILITY = 'createorupdateotherassetliability';
export const CREATEORUPDATEOTHERASSETLIABILITYREVALUATION = 'createorupdateotherassetliabilityrevaluation';
export const GETCOUPONDUE4SELECTION = 'getcoupondue4selection';
export const RECEIVEBULKCOUPON = 'receivebulkcoupon';
export const CREATEORUPDATERECEIVEDCOUPON = 'createorupdatereceivedcoupon';
export const APPROVERECLASSIFYFIXEDINCOME = 'approvereclasifiedfixedIncome';

// fee profile
export const GETPORTFOLIOTYPES = 'getportfoliotypes';
export const GETMAPPEDPORTFOLIOSTOFEEPROFILE = 'getmappedportfoliostofeeprofile'; // /id
export const GETUNMAPPEDPORTFOLIOSFROMFEEPROFILE = 'getunmappedportfoliosfromfeeprofile'; // /id
export const UNMAPPORTFOLIOSFROMFEEPROFILE = 'unmapportfoliosfromfeeprofile';
export const MAPPORTFOLIOSTOFEEPROFILE = 'mapportfoliostofeeprofile';
export const GETFEEPROFILEBYTYPE = 'getfeeprofilebytype'; // /type
export const GETFEEMEASUREMENTTYPE = 'getfeemeasurementtype'; // /type
export const GETFEEPERIODTYPES = 'getfeeperiodtypes';
export const GETFEEMATRIXSETUPDETAILSINGLE = 'getfeematrixsetupdetailsingle'; // /type
export const GETSTEPFEERATERANGE = 'getstepfeeraterange'; // /feeid
export const CREATEINCENTIVEFEEPROFILE = 'createincentivefeeprofile';
export const CREATEORUPDATESTEPFEERATERANGE = 'createorupdatestepfeeraterange';
export const UPDATEINCENTIVEFEEPROFILE = 'updateincentivefeeprofile';
export const CREATENETASSETFEEPROFILE = 'createnetassetfeeprofile';
export const UPDATENETASSETFEEPROFILE = 'updatenetassetfeeprofile';
export const CREATEINCOMEBASEDFEEPROFILE = 'createincomebasedfeeprofile';
export const UPDATEINCOMEBASEDFEEPROFILE = 'updateincomebasedfeeprofile';
export const CREATEFIXEDAMOUNTFEEPROFILE = 'createfixedamountfeeprofile';
export const UPDATEFIXEDAMOUNTFEEPROFILE = 'updatefixedamountfeeprofile';
export const DELETEFEEPROFILE = 'deletefeeprofile'; // /id
export const GETFEEMATRIXSETUPBYPORTFOLIO = 'getfeematrixsetupbyportfolio'; // /id
export const GETFEEMATRIXSETUPDETAIL = 'getfeematrixsetupdetail'; // /id
export const CREATEFEESETUPFROMFEEPROFILE = 'createfeesetupfromfeeprofile';
export const CREATEFIXEDFEESETUPDETAIL = 'createfixedfeesetupdetail';
export const DELETEFEESETUPDETAIL = 'deletefeesetupdetail'; // /id
export const UPDATEFIXEDFEESETUPDETAIL = 'updatefixedfeesetupdetail';
export const ENABLEDISABLEFEESETUP = 'enabledisablefeesetup';
export const CREATENAVFEESETUPDETAIL = 'createnavfeesetupdetail';
export const UPDATENAVFEESETUPDETAIL = 'updatenavfeesetupdetail';
export const CREATEINCENTIVEFEESETUPDETAIL = 'createincentivefeesetupdetail';
export const UPDATEINCENTIVEFEESETUPDETAIL = 'updateincentivefeesetupdetail';
export const GETFEEPROFILEFORSELECTION = 'getfeeprofileforselection';
export const DELETEFEESETUP = 'deletefeesetup'; // /transid
export const INITIATEWORKFLOWPROCESS = 'initiateworkflowprocess'; // processNumber
export const APPROVEMANAGEMENTFEEMATRIX = 'approvemanagementfeematrix'; //

//Events

export const GETUSERPORTFOLIOEVENTS = 'getuserportfolioevents';
export const CREATEORUPDATEPORTFOLIOEVENTSUBSCRIBER = 'createorupdateportfolioeventsubscriber';
